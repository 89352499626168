import React, { useState } from 'react';
import classNames from 'classnames';

export const ApplicationContext = React.createContext();

export const ApplicationProvider = ({ children }) => {
  const [mobileMenuIsOpen, setMobileMenuOpenState] = useState(false);

  const toggleMenu = (event) => {
    event && event.preventDefault();
    let mobileMenuState = mobileMenuIsOpen;
    setMobileMenuOpenState(!mobileMenuState);
  };

  const getBodyClassNames = (className) => {
    return classNames(className, {
      'nav-closed': !context.state.mobileMenuIsOpen,
      'nav-opened': context.state.mobileMenuIsOpen,
    });
  };

  const context = {
    state: {
      mobileMenuIsOpen,
    },
    actions: {
      toggleMenu,
      getBodyClassNames,
    },
  };

  return (
    <ApplicationContext.Provider value={context}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const nullContext = {
  state: {
    mobileMenuIsOpen: false,
  },
  actions: {
    toggleMenu: () => {},
    getBodyClassNames: () => {},
  },
};
