import React from 'react';
import Footer from '../Footer';
import Navigation from '../Navigation';

const Main = ({ children }) => {
  return (
    <main>
      <Navigation />

      <section className="site-wrapper">
        {children}
        <Footer />
      </section>
    </main>
  );
};

export default Main;
