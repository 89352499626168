import React from 'react';
import { Link } from 'gatsby';
import useSiteMetadata from './SiteMetadata';

const Footer = () => {
  const { title } = useSiteMetadata();

  return (
    <footer className="site-footer clearfix">
      <section className="copyright">
        <Link to="/">{title}</Link> &copy; {new Date().getFullYear()}
      </section>
      <section className="poweredby">
        Proudly published with <a href="https://ghost.org">Ghost</a>
      </section>
    </footer>
  );
};

export default Footer;
