import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { ApplicationContext } from './Contexts/ApplicationContext';

const Navigation = () => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     active: false,
  //     navBarActiveClass: '',
  //   };
  // }

  // toggleHamburger = () => {
  //   // toggle the active boolean in the state
  //   this.setState(
  //     {
  //       active: !this.state.active,
  //     },
  //     // after state has been updated,
  //     () => {
  //       // set the class in state for the navbar accordingly
  //       this.state.active
  //         ? this.setState({
  //             navBarActiveClass: 'is-active',
  //           })
  //         : this.setState({
  //             navBarActiveClass: '',
  //           });
  //     }
  //   );
  // };
  const context = useContext(ApplicationContext);

  return (
    <>
      <nav className="nav" role="navigation" aria-label="main-navigation">
        <a
          href="#menu"
          className="nav-close"
          onClick={context.actions.toggleMenu}
        >
          <span className="hidden">Close</span>
        </a>
        <ul>
          <li className="nav-home nav-current">
            <Link to="/">Home</Link>
          </li>
        </ul>
        <Link className="subscribe-button" to="/subscribe">
          Subscribe
        </Link>
      </nav>
      <span className="nav-cover" onClick={context.actions.toggleMenu}></span>
    </>
  );
};

export default Navigation;
