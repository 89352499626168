import React from 'react';
import { Helmet } from 'react-helmet';

import useSiteMetadata from './SiteMetadata';
import { ApplicationProvider } from './Contexts/ApplicationContext';
import Main from './Layout/Main';
import BasicMetadata from './Layout/BasicMetadata';
import FooterScripts from './Layout/FooterScripts';
import '../sass/global.scss';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <ApplicationProvider>
      <Helmet>
        <html className="casper-theme" />
      </Helmet>
      <BasicMetadata title={title} description={description} />
      <Main>{children}</Main>
      <FooterScripts />
    </ApplicationProvider>
  );
};

export default TemplateWrapper;
